import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} Improve your skills and increase your productivity in trading with our training. Our product is designed for both beginners who are not familiar with the world of trading and for traders who want to improve their knowledge and be in the best team and community.</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="TAKE PROFIT - Learn trading with community " />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


